/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.slick-slide {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
  height: 55ch;
}

::-webkit-scrollbar-track {
  background-color: white;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #eeeeee;
  border-radius: 1rem;
  border: 3px solid transparent;
  background-clip: content-box;
  width: 2px;
}

::-moz-scrollbartrack-vertical {
  background-color: rgb(255, 255, 255);
}

::-moz-scrollbar {
  width: 10px;
  height: 10px;
}

::-moz-scrollbarbutton-up {
  background-color: gray;
}

@media only screen and (max-width: 600px) {
  .slick-slide {
    margin: 0;
  }
  .slick-list {
    margin: 0;
    height: fit-content;
  }
}
